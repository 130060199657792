<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tiêu đề </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailMediaPress.name"
            v-model="detailMediaPress.name"
            @input="onKeyUp"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
          <hnr-input :text="slug" v-model="detailMediaPress.slug"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Nội dung </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="content" rules="required" v-slot="{ errors }">
          <v-textarea
              outlined
              name="input-7-4"
              label="Nhập nội dung báo"
              v-model="detailMediaPress.content"
              rows="5"
          ></v-textarea>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Link đến trang nội dung </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="link" rules="required" v-slot="{ errors }">
          <hnr-input
            :text="detailMediaPress.link"
            v-model="detailMediaPress.link"
          ></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tải ảnh logo báo </span>
      </v-col>
      <v-col cols="10" class="pa-1 d-flex align-center">
        <v-file-input
          class="pl-1"
          prepend-icon="mdi-camera"
          v-model="img"
          label="Tải logo"
          :value="detailMediaPress.logo"
          @change="onChangeFileUploadLogo"
          @click:clear="clearLogo"
          :text="detailMediaPress.logo"
        ></v-file-input>
        <v-col cols="2" class="pa-4">
          <v-img
            v-if="logoPreviewUrl || replace_logo"
            :src="logoPreviewUrl || replace_logo"
            contain
            class="image-container"
          ></v-img>
        </v-col>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tải ảnh nội dung</span>
      </v-col>
      <v-col cols="10" class="pa-1 d-flex align-center">
        <v-file-input
          class="pl-1"
          prepend-icon="mdi-camera"
          v-model="image"
          label="Tải ảnh"
          @change="onChangeFileUploadImage"
          @click:clear="clearImage"
        ></v-file-input>
        <v-col cols="2" class="pa-4">
          <v-img
            :src="imagePreviewUrl || replace_image"
            v-if="imagePreviewUrl || replace_image"
            contain
            class="image-container"
          ></v-img>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import mixin from "@/store/mixin";
import CkContent from "@/components/ck-content.vue";
import { SAVE_IMAGE } from "@/store/image.module";

export default {
  mixins: [mixin],
  props: {
    mediapress: Object,
    isNew: { type: Boolean, default: false },
  },
  components: {
    CkContent,
    HnrInput,
  },
  data() {
    return {
      image: {},
      img: {},
      show: true,
      detailMediaPress: {logo: "",  // Tên ảnh logo từ DB
      image: "",},
      name: "",
      slug: "",
      logoPreviewUrl: null,  // Preview for logo
      imagePreviewUrl: null, // Preview for content image
    };
  },
  computed: {
    replace_image() {
      return this.detailMediaPress.image;
    },
    replace_logo() {
      return this.detailMediaPress.logo;
    },
  },

  watch: {
    mediapress: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailMediaPress = newVal;
      },
    },
    detailMediaPress: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.slug = newVal.slug;
      },
    },
  },
  methods: {
    onKeyUp(value) {
      this.detailMediaPress.slug = this.sanitizeTitle(value);
    },
    onChangeFileUploadLogo() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.img);
      this.$store.dispatch(SAVE_IMAGE, formData).then((data) => {
        if (data.status == 1 && data.url) {
          this.detailMediaPress.logo = data.url;
          this.logoPreviewUrl = data.url;
        }
      });
    },
    clearLogo() {
      this.detailMediaPress.img = "";
    },

    onChangeFileUploadImage() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(SAVE_IMAGE, formData).then((data) => {
        if (data.status == 1 && data.url) {
          this.detailMediaPress.image = data.url;
          this.imagePreviewUrl = data.url;
        }
      });
    },
    clearImage() {
      this.detailMediaPress.image = "";
    },
  },
};
</script>
