<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="item in items" :key="item.id">{{ item.tab }}</v-tab>
      </v-tabs>
      <v-tabs-items class="tab-content" v-model="tabIndex">
        <v-tab-item>
          <media-press-content
            class="tab-detail"
            :mediapress="detailMediaPress"
            :isNew="isCreate"
          ></media-press-content>
        </v-tab-item>
      </v-tabs-items>
      <v-row v-if="isCreate" class="align-center justify-center ma-0 pt-5">
        <v-btn color="primary" @click="handleSubmit(saveSubject)">Tạo mới bài báo</v-btn>
      </v-row>
      <v-row v-else class="align-center justify-center ma-0 pt-5">
        <v-btn color="primary" @click="handleSubmit(updateSubject)">Cập nhật bài báo</v-btn>
      </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { SET_LOADING } from "@/store/loading.module";
import { SAVE_MEDIA_PRESS, GET_DETAIL_MEDIA_PRESS, UPDATE_MEDIA_PRESS } from "@/store/mediapress.module";
import { SUCCESS, ERROR } from "@/store/alert.module";
import MediaPressContent from '../../../components/media_press/MediaPressContent.vue';

export default {
  data() {
    return {
      id: "",
      isCreate: true,
      tabIndex: 0,
      items: [{ id: 0, tab: "Thông tin báo chí" }],
      detailMediaPress: {
        id: '',
        name: "",
        slug: "",
        content: "",
        image: "",
        logo: "",
        link: "",
       
      },
    };
  },
  components: {
    MediaPressContent,
  },
  computed: {
    ...mapGetters({
      mediapress: "detailMediaPress",
    }),
  },
  watch: {
    mediapress: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailMediaPress = newVal;
      },
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    if (this.id) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sửa báo" }]);

      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(GET_DETAIL_MEDIA_PRESS, this.id)
        .then(() => {
          this.$store.dispatch(SET_LOADING, false);
        })
        .catch(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
    } else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thêm báo" }]);
    }
  },
  methods: {
    updateSubject() {
      let payload = {
        id: this.id,
        name: this.detailMediaPress.name,
        slug: this.detailMediaPress.slug,
        content: this.detailMediaPress.content,
        link: this.detailMediaPress.link,
        image: this.detailMediaPress.image,
        logo: this.detailMediaPress.logo,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(UPDATE_MEDIA_PRESS, payload)
        .then((data) => {
          if (data) {
            this.$store.dispatch(SUCCESS, "Cập nhật báo thành công!", { root: true });
            this.$router.push("/media-press");
            this.detailMediaPress = {};
          } else {
            this.$store.dispatch(ERROR, data.message, { root: true });
          }
          this.$store.dispatch(SET_LOADING, false);
        })
        .catch(() => {
          this.$store.dispatch(ERROR, "Cập nhật báo thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
    },
    saveSubject() {
      let payload = {
        name: this.detailMediaPress.name,
        slug: this.detailMediaPress.slug,
        content: this.detailMediaPress.content,
        link: this.detailMediaPress.link,
        image: this.detailMediaPress.image,
        logo: this.detailMediaPress.logo,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(SAVE_MEDIA_PRESS, payload)
        .then((data) => {
          if (data.status) {
            this.$store.dispatch(SUCCESS, "Thêm bài báo thành công!", { root: true });
            this.$router.push("/media-press");
          } else {
            this.$store.dispatch(ERROR, data.message, { root: true });
          }
          this.$store.dispatch(SET_LOADING, false);
        })
        .catch(() => {
          this.$store.dispatch(ERROR, "Thêm bài báo thất bại!", { root: true });
          this.$store.dispatch(SET_LOADING, false);
        });
    },
  },
};
</script>

<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>